<template>
  <!-- 最外层父元素 -->
  <div class="resultFather" v-loading="loading">
    <div class="firstChild">
      <!-- 左上角 -->
      <div class="margin-bottom:20px;">
        <!-- 搜索结果 -->
        <p
          style="
            margin: 0px;
            font-weight: 600;
            font-size: 16px;
            color: #080707;
            height: 50px;
            line-height: 50px;
          "
        >
          {{ $t("label.search.result") }}
        </p>
      </div>
      <!-- 有结果单个搜索对象就显示 -->
      <div
        class="positionG"
        v-show="
          hasResult === false ? (isSingle === true ? true : false) : hasResult
        "
        v-if="
          isSingleObj === false
            ? isSingle === true
              ? true
              : false
            : isSingleObj
        "
      >
        <div class="classify" @click="goto">
          <!-- 热门结果 -->
          {{ $t("vue_label_popularresults") }}
        </div>
        <!-- 单个搜索对象 -->
        <div
          class="classify classifyY"
          :style="{
            background: checkedObj !== 'all' ? '#F5F5F5' : 'white',
            color: checkedObj !== 'all' ? '#006DCC' : '#2c3e50',
          }"
        >
          <div>{{ selectObjTlabel }}</div>
          <div>({{  resultCount }})</div>
          <!-- <div>({{ resultCount > 10 ? "10" : resultCount }})</div> -->
        </div>
      </div>
      <!-- 有结果不是单个搜索对象 -->
      <div class="position" v-show="hasResult" v-if="!isSingleObj">
        <div class="classify classifyY" @click="showHotResult">
          <!-- 热门结果 -->
          {{ $t("vue_label_popularresults") }}
        </div>
        <div
          class="classify"
          v-for="(item, index) in searchResult"
          :key="index"
          @click="choseObj(item)"
        >
          <div>{{ item.objectInfo.tlabel }}</div>
          <div>({{ item.count }})</div>
        </div>
      </div>
      <el-collapse
        v-model="activeNames"
        v-if="this.$route.params.searchObjId === 'all' ? false : true"
      >
        <!-- 展开列表 -->
        <el-collapse-item
          :title="$t('vue_label_globalsearch_expandlist')"
          name="1"
        >
          <div
            class="classify collapse"
            v-for="(item, index) in expandMore"
            :key="index"
            @click="Single(item)"
          >
            <div>{{ item.tlabel }}</div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div
        class="screen"
        v-if="this.$route.params.searchObjId === 'all' ? false : true"
      >
        <p class="screen_title">
          <!-- 调整方式 -->
          {{ $t("vue_label_globalsearch_adjustment") }}
          <span class="search">
            <span class="search_item" @click="clear">
              <!-- 清除 -->
              {{ $t("label.delete.condition") }}
            </span>
            <span class="search_item" @click="search" @keyup.enter="search">
              <!-- 搜索 -->
              {{ $t("label_partner_workspace_member_search") }}
            </span>
          </span>
        </p>
        <div v-for="(item, index) in screenDataList" :key="index">
          <div style="font-size: 12px">{{ item.nameLabel }}</div>
          <EditableCell
            ref="editableCell"
            :options="selectorList"
            :input-type="item.type"
            :fieldName="item.id"
            @selectes="selectes(item)"
            @searchEnter="search"
          ></EditableCell>
        </div>
      </div>
    </div>
    <!-- 右侧 -->
    <!-- 无结果 -->
    <div class="secondChildY" v-show="!hasResult" v-if="!isSingleObj">
      <div class="noResultIcon">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-empty-search"></use>
        </svg>
      </div>
      <div class="noResultInfo">
        <!-- 我们已搜索您常用的搜索项，未找到与{{reciveInput.userInput}}匹配的结果 -->
        <p>
          <!-- 我们已搜索您常用的搜索项，未找到与{{reciveInput.userInput}}匹配的结果 -->
          {{
            $t("vue_label_allsearch_noresults", {
              userInput: reciveInput.userInput,
            })
          }}
        </p>
        <!-- 您可以在搜索框左侧的下拉框中设置更多的搜索项来继续查找 -->
        <p>
          <!-- 您可以在搜索框左侧的下拉框中设置更多的搜索项来继续查找 -->
          {{ $t("vue_label_allsearch_set_more_items") }}
        </p>
      </div>
    </div>
    <!-- 有结果 单个搜索对象 -->
    <div class="secondChildY" v-if="isSingleObj" v-show="hasResult">
      <div style="padding: 25px 0px 10px 10px">
        <p style="color: #080707">{{ selectObjTlabel }}</p>
      </div>
      <div class="orderTitle">
        <!--          条结果          排序方式 -->
        <div style="margin-right: 20px">
          <!-- {{ resultCount > 10 ? 10 : resultCount
          }} -->
           {{ resultCount}}
          <span style="margin-left: 5px">{{ $t("label.reportrecords") }}</span
          >--{{ $t("setup.layout.label.order") }}
        </div>
        <el-dropdown>
          <div class="order">
            <p style="font-size: 14px">
              {{ orderSize == "" ? $t("label.default") : orderSize }}
            </p>
            <div
              @click="openOrder"
              class="el-icon-caret-bottom orderIcon"
            ></div>
          </div>
          <el-dropdown-menu>
            <!-- 排序弹窗 -->
            <div
              class="show-order"
              @click="closeOrder"
              style="width: 160px; background-color: white"
            >
              <!-- <div @click="closeOrder" style="width:150px;height:200px;background-color:blue"></div> -->
              <p style="padding: 15px 0px 5px 17px; color: #080707">
                {{ $t("label.orderby") }}
              </p>
              <!-- 排序 -->
              <div
                class="divStyle"
                @click="defaultOrder"
                :style="{
                  background: orderSize == '' ? '#ECF5FF' : 'white',
                  color: orderSize == '' ? '#7ac2fd' : '#080707',
                }"
              >
                {{ $t("label.default") }}{{ $t("label.sort") }}
              </div>
              <div
                v-for="(item, index) in searchResult.fieldList"
                :key="index"
                class="divStyle"
                :style="{
                  background: item.nameLabel == orderSize ? '#ECF5FF' : 'white',
                  color: item.nameLabel == orderSize ? '#7ac2fd' : '#2c3e50',
                }"
                @click="choseOrder(index)"
              >
                {{ item.nameLabel }}
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="seeMore" @click="jumpMore">查看更多</div> -->
      </div>
      <!-- 筛选 -->
      <div class="selector">
        <div
          class="selector-item"
          v-for="(Sitem, index) in searchResult.fieldList"
          :key="index"
        >
          {{ Sitem.nameLabel }}
          <!-- 内容自动换行或者剪辑文本 -->
              <!-- 默认内容自动换行 -->
              <el-popover
                placement="bottom-start"
                :visible-arrow="false"
                trigger="hover">
                <ul >
                  <li v-for="item in textList" :key="item.id" class="cursorBox liBox" @click.stop="changeActive(Sitem,item,searchResult.objectInfo)">
                    {{$t(item.label)}}
                    <!-- 选中标识 -->
                    <i v-show="liFilter(Sitem,item)" class="el-icon-check"></i>
                  </li>
                </ul>
                <i slot="reference" class="el-icon-arrow-down el-icon--right cursorBox"></i>
              </el-popover>
        </div>
      </div>
      <!-- 结果区域 -->
      <div
        class="resultArea selector"
        v-for="(item, index) in searchResult.dataList"
        :key="index"
      >
        <div
          class="resultItem"
          v-for="(Nitem, index) in searchResult.fieldList"
          :key="index"
          @click.exact="jumpDetail($event, item, Nitem,'detail')"
          @click.ctrl.exact="jumpDetail($event, item, Nitem, true)"
          @click.meta.exact="jumpDetail($event, item, Nitem, true)"
          :style="{
            color:
              Nitem.schemefieldType === 'Y'
                ? '#006DCC'
                : Nitem.schemefieldName === 'name'
                ? '#006DCC'
                : Nitem.schemefieldType === 'N'
                ? '#006DCC'
                : '#080707',
            cursor:
              Nitem.schemefieldType === 'Y'
                ? 'pointer'
                : Nitem.schemefieldName === 'name'
                ? 'pointer'
                : Nitem.schemefieldType === 'N'
                ? 'pointer'
                : 'auto',
          }"
        >
          <span
          :class="{autoWrap:Nitem.showtype !== '1',clipText: Nitem.showtype === '1'}"
            v-if="Nitem.schemefieldType == 'Y' || Nitem.schemefieldType == 'R'"
          >
            <template v-if="Nitem.schemefieldType == 'F'">{{
              item[Nitem.schemefield_name + "ccname"]
                | datetimeFormat(countryCode)
            }}</template>
            <template v-else-if="Nitem.schemefieldType == 'D'">{{
              item[Nitem.schemefield_name + "ccname"] | dateFormat(countryCode)
            }}</template>
            <template v-else>{{ item[Nitem.schemefield_name + "ccname"] }}</template>
          </span>
          <span :class="{autoWrap:Nitem.showtype !== '1',clipText: Nitem.showtype === '1'}"  v-else>
            <template v-if="Nitem.schemefieldType == 'F'">{{
              item[Nitem.schemefield_name] | datetimeFormat(countryCode)
            }}</template>
            <template v-else-if="Nitem.schemefieldType == 'D'">{{
              item[Nitem.schemefield_name] | dateFormat(countryCode)
            }}</template>
            <template v-else>{{ item[Nitem.schemefield_name] }}</template>
          </span>
        </div>
      </div>
    </div>

    <!-- 有结果  全部搜索对象 -->
    <div class="secondChild" v-if="!isSingleObj" v-show="hasResult">
      <!-- 单独对象的结果（好像没有展示的情况，showHot永远为true） -->
      <div
        v-if="!showHot"
        style="background: white; height: 100%; border-radius: 4px"
      >
        <div style="padding: 25px 0px 10px 10px">
          <p style="color: #080707">{{ selectObjTlabel }}</p>
        </div>
        <div class="orderTitle" >
          <div style="margin-right: 20px">
            <span style="margin-right: 5px">{{ resultCount }}</span
            >{{ $t("label.reportrecords") }}
          </div>
        </div>
        <!-- <div class="seeMore" @click="jumpMore">查看更多</div> -->
        <!-- 排序弹窗 -->
        <!--<div class="show-order" @click="closeOrder" v-show="showOrder" style="width:160px;background-color:white">
					<div @click="closeOrder" style="width:150px;height:200px;background-color:blue"></div>
					<p style="padding:15px 0px 0px 17px">{{$t('label.orderby')}}</p>
					<div>{{$t('label.default')}}{{$t('label.sort')}}</div>
					<div v-for="(item,index) in selectObjData.fieldList"
						:key="index">{{item.nameLabel}}</div>
				</div>-->
        <!-- 筛选 -->
        <div class="selector">
          <div
            class="selector-item"
            v-for="(Sitem, index) in selectObjData.fieldList"
            :key="index"
          >
            {{ Sitem.nameLabel }}
            <!-- 内容自动换行或者剪辑文本 -->
              <!-- 默认内容自动换行 -->
              <el-popover
                placement="bottom-start"
                :visible-arrow="false"
                trigger="hover">
                <ul >
                  <li v-for="item in textList" :key="item.id" class="cursorBox liBox" @click.stop="changeActive(Sitem,item,selectObjData.objectInfo)">
                    {{$t(item.label)}}
                    <!-- 选中标识 -->
                    <i v-show="liFilter(Sitem,item)" class="el-icon-check"></i>
                  </li>
                </ul>
                <i slot="reference" class="el-icon-arrow-down el-icon--right cursorBox"></i>
              </el-popover>
          </div>
        </div>
        <!-- 结果区域 -->
        <div
          class="resultArea selector"
          v-for="(item, index) in selectObjData.dataList"
          :key="index"
        >
          <div
            class="resultItem"
            v-for="(Nitem, index) in selectObjData.fieldList"
            :style="{
              color:
                Nitem.schemefieldType === 'Y'
                  ? '#006DCC'
                  : Nitem.schemefieldName === 'name'
                  ? '#006DCC'
                  : Nitem.schemefieldType === 'N'
                  ? '#006DCC'
                  : '#080707',
              cursor:
                Nitem.schemefieldType === 'Y'
                  ? 'pointer'
                  : Nitem.schemefieldName === 'name'
                  ? 'pointer'
                  : Nitem.schemefieldType === 'N'
                  ? 'pointer'
                  : 'auto',
            }"
            :key="index"
            @click.exact="jumpDetail($event, item, Nitem,'detail')"
            @click.ctrl.exact="jumpDetail($event, Titem, Nitem, true)"
          @click.meta.exact="jumpDetail($event, Titem, Nitem, true)"
          >
            <span
              v-if="
                Nitem.schemefieldType == 'Y' || Nitem.schemefieldType == 'R'
              "
            >
              <span v-if="Nitem.schemefieldType == 'F'">{{
                item[Nitem.schemefield_name + "ccname"]
                  | datetimeFormat(countryCode)
              }}</span>
              <span v-else-if="Nitem.schemefieldType == 'D'">{{
                item[Nitem.schemefield_name + "ccname"]
                  | dateFormat(countryCode)
              }}</span>
              <span v-else>{{ item[Nitem.schemefield_name + "ccname"] }}</span>
            </span>
            <span v-else>
              <span v-if="Nitem.schemefieldType == 'F'">{{
                item[Nitem.schemefield_name] | datetimeFormat(countryCode)
              }}</span>
              <span v-else-if="Nitem.schemefieldType == 'D'">{{
                item[Nitem.schemefield_name] | dateFormat(countryCode)
              }}</span>
              <span v-else>{{ item[Nitem.schemefield_name] }}</span>
            </span>
            <!-- {{Nitem.schemefieldType=='Y' ||Nitem.schemefieldType=='R'?`${item[Nitem.schemefield_name+'ccname']}`:item[Nitem.schemefield_name]}} -->
          </div>
        </div>
      </div>
      <!-- 热门搜索结果 -->
      <div v-if="showHot">
        <!-- 单独是数据div -->
        <div
          v-for="(item, index) in searchResult"
          :key="index"
          class="singleHot"
        >
          <!-- 描述 -->
          <div style="padding: 25px 0px 10px 10px">
            <p style="font-size: 16px">{{ item.objectInfo.tlabel }}</p>
          </div>
          <div class="orderTitle flexBox">
            <div style="margin-right: 20px; font-size: 14px">
              {{ item.count }}{{ $t("label.reportrecords") }}
            </div>
             <!-- 查看更多按钮，跳转到单个搜索对象页面 -->
          <el-button v-if="Number(item.count)>10 || item.count ==='10+'" size="small" plain @click="choseObj(item)">{{$t("label_tabpage_lookatmorez")}}</el-button>
          </div>
          <!-- 数据内容 -->
          <!-- 筛选 -->
          <div class="selector">
            <div
              class="selector-item"
              v-for="(Sitem, Sindex) in item.fieldList"
              :key="Sindex"
            >
              {{ Sitem.nameLabel }}
              <!-- 内容自动换行或者剪辑文本 -->
              <!-- 默认内容自动换行 -->
              <el-popover
                placement="bottom-start"
                :visible-arrow="false"
                trigger="hover">
                <ul >
                  <li v-for="textItem in textList" :key="textItem.id" class="cursorBox liBox" @click.stop="changeActive(Sitem,textItem,item.objectInfo)">
                    <!-- {{textItem.label}}  -->
                    {{$t(textItem.label)}}
                    <!-- 选中标识 -->
                    <i v-show="liFilter(Sitem,textItem)" class="el-icon-check"></i>
                  </li>
                </ul>
                <i slot="reference" class="el-icon-arrow-down el-icon--right cursorBox"></i>
              </el-popover>

            </div>
          </div>
          <!-- 结果区域 -->
          <div
            class="resultArea selector"
            v-for="(Titem, Tindex) in item.dataList"
            :key="Tindex"
          >
            <div
              class="resultItem"
              v-for="(Nitem, Nindex) in item.fieldList"
              :style="{
                color:
                  Nitem.schemefieldType === 'Y'
                    ? '#006DCC'
                    : Nitem.schemefieldName === 'name'
                    ? '#006DCC'
                    : Nitem.schemefieldType === 'N'
                    ? '#006DCC'
                    : '#080707',
                cursor:
                  Nitem.schemefieldType === 'Y'
                    ? 'pointer'
                    : Nitem.schemefieldName === 'name'
                    ? 'pointer'
                    : Nitem.schemefieldType === 'N'
                    ? 'pointer'
                    : 'auto',
              }"
              :key="Nindex"
              @click.exact="jumpDetail($event, Titem, Nitem,'detail')"
              @click.ctrl.exact="jumpDetail($event, Titem, Nitem, true)"
              @click.meta.exact="jumpDetail($event, Titem, Nitem, true)"
            >
              <span
              :class="{autoWrap:Nitem.showtype !== '1',clipText: Nitem.showtype === '1'}"
                v-if="
                  Nitem.schemefieldType == 'Y' || Nitem.schemefieldType == 'R'
                "
              >
                <template v-if="Nitem.schemefieldType == 'F'">{{
                  Titem[Nitem.schemefield_name + "ccname"]
                    | datetimeFormat(countryCode)
                }}</template>
                <template v-else-if="Nitem.schemefieldType == 'D'">{{
                  Titem[Nitem.schemefield_name + "ccname"]
                    | dateFormat(countryCode)
                }}</template>
                <template v-else>{{
                  Titem[Nitem.schemefield_name + "ccname"]
                }}</template>
              </span>
              <span :class="{autoWrap:Nitem.showtype !== '1',clipText: Nitem.showtype === '1'}" v-else>
                <template v-if="Nitem.schemefieldType == 'F'">{{
                  Titem[Nitem.schemefield_name] | datetimeFormat(countryCode)
                }}</template>
                <template v-else-if="Nitem.schemefieldType == 'D'">{{
                  Titem[Nitem.schemefield_name] | dateFormat(countryCode)
                }}</template>
                <template v-else>{{ Titem[Nitem.schemefield_name] }}</template>
              </span>
              <!-- {{Nitem.schemefieldType=='Y' ||Nitem.schemefieldType=='R'?`${Titem[Nitem.schemefield_name+'ccname']}`:Titem[Nitem.schemefield_name]}} -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 全局搜索对象
 */
import {
  getSingleGlobalSearchResult,
  getGlobalSearchResult,
  getObjectSetting,
  GetViewGetSelectValue,
  saveGlobalSearch
} from "./api.js";
import * as Time from "@/utils/time";
import INPUTTYPE from "@/config/enumCode/searchTableInputType";
import EditableCell from "../../components/TablePanel/EditableCell";
import { mapState } from "vuex";
import { consoleSaveTab } from "@/views/home/api.js";

export default {
  components: {
    EditableCell,
  },
  data() {
    return {
      // 表格表头下拉列表数据
      // 自动换行:c2164   剪辑文本:c2165
      textList: [{id: '0', label:'c2164'},{id: '1', label:'c2165'}],
      resultCount: 0,
      // 有无结果
      hasResult: true,
      //排序弹窗显示
      showOrder: false,
      //是否搜索单个对象
      isSingleObj: true,
      //父组件传递来的对象
      reciveInput: {},
      //保存搜索结果
      searchResult: [],
      //排序种类
      orderSize: "",
      //保存当前选中的搜索对象下标
      objIndex: 0,
      //保存当前选中对象的数据
      selectObjData: [],
      //保存当前选中搜索对象的API
      selectObjApi: "",
      //保存当前选中对象的prefix
      selectObjPre: "",
      //保存当前选中对象的tlabel
      selectObjTlabel: "",
      loading: false,
      showHot: true,
      checkedObj: "all",
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      timeFormat: Time.timeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
      activeNames: [], // 展开列表判断,
      expandMore: null, // 展开更多
      screenDataList: null, // 筛选器数据
      selectorList: [],
      isSingle: false, // 单个对象
      isClear: null,
    };
  },
  mounted() {
    // 跳转到搜索结果页时，菜单栏添加搜索框
    this.$Bus.$emit("enterSelectBtn");
    this.reciveInput = this.$route.params;
    if (this.$route.params.searchObjId == "all") {
      this.isSingleObj = false;
      this.loading = true;
      this.getGlobalResult();
    } else {
      this.getGloObj();
      this.isSingleObj = true;
      this.loading = true;
      this.getSingleGlobalResult();
    }
  },
  watch: {
    $route() {
      // 在服务控制台应用程序下对一级菜单进行缓存
      if(!this.$store.state.navigationStyle){
        this.activeNames = [];
        this.loading = true;
        if (this.$route.params.searchObjId == "all") {
          this.checkedObj = this.$route.params.searchObjId;
          this.getGlobalResult();
        } else {
          this.checkedObj = this.$route.params.searchObjId;
          this.getGloObj();
          this.getSingleGlobalResult();
        }
        this.reciveInput.userInput = this.$route.params.userInput;
        this.orderSize = "";
        // this.$Bus.$emit("enterSelectBtn");
      }
    },
  },
  computed: {
    ...mapState({consoleData:(state)=>state.consoleData}),
  },
  methods: {
    /**
     * 调用接口保存字段展示状态
     * @param {Object} field:字段信息
     * @param {Object} objid:对象objid
     */
     saveGlobalSearch(field = {}, objid){
      let showtypeSettingId = field.showtypeSettingId||field.showtype_setting_id
      let param = {
        objid: objid,
        fieldid: field.schemefieldName,
        showtype: field.showtype

      }
      if(showtypeSettingId){
        param.id = showtypeSettingId
      }
      saveGlobalSearch(param)
     },
    /**
     * 切换当前表格下拉列表的选中状态
     * @param {*} item :表格对象
     * @param {*} liItem ：当前下拉列表选项
     * @param {*} objectInfo ：当前对象信息
     */
     changeActive(item={},liItem={},objectInfo= {}){
      this.$set(item,'showtype',liItem.id)
      // 需要调接口保存
      if(objectInfo && objectInfo.objid){
        this.saveGlobalSearch(item,objectInfo.objid)
      }


     },
    /**
     * 判断当前表格内容展示样式
     * @param {*} item :表格对象
     * @param {*} liItem ：当前下拉列表选项
     */
    liFilter(item={},liItem={}){
      // 如果有选中，选中内容决定
      if(item.showtype){
        return item.showtype === liItem.id
      }else{
        // 默认展示自动换行
        return '0' === liItem.id
      }
    },
    showHotResult() {
      this.showHot = true;
      this.checkedObj = "all";
    },
    /**
     *
     * @param {*} event
     * @param {*} data
     * @param {*} field
     * @param {*} flag :是否是ctrl（meta）+左键
     */
    jumpDetail(event, data, field, flag) {
      if (event.srcElement.innerHTML == "  ") {
        return;
      } else if (field.schemefieldName === "name") {
        //  获取id
        let id = data.id;
        if(this.$store.state.navigationStyle && flag ){
          let menuObj={
            name: data[field.schemefield_name], // tab名称
            id: id, //id，唯一标识tab的
            routerName:'consoleDetail',//路由的名字
            params:{
              page:id,
              oprateType:'DETAIL',
              id,
            },// 页面需要的参数
          }
          window.$CCDK.CCMenu.addMenu1(menuObj);
          if(flag==='detail'){
            this.$router.push({
              path: `/commonObjects/console-multi-screen/console-detail/${id}/DETAIL?dataId=${id}`,
            });
          }else if(flag===true){
            this.consoleData.data.level1Id = "menu";
          }
          return;
        }else if(!this.$store.state.navigationStyle &&flag){
          const newPage = this.$router.resolve({
            path: `/commonObjects/detail/${id}/DETAIL`,
          });
          window.open(newPage.href, "_blank");
        }else{
          this.$router.push({
          path: `/commonObjects/detail/${id}/DETAIL`,
        });
        }

      } else if (field.schemefieldType == "Y" || field.schemefieldType == "N") {
        let id = data[field.schemefieldName];
        if(this.$store.state.navigationStyle && flag ){
          let menuObj={
            name: data[field.schemefield_name + "ccname"], // tab名称
            id: id, //id，唯一标识tab的
            routerName:'consoleDetail',//路由的名字
            params:{
              page:id,
              oprateType:'DETAIL',
              id,
            },// 页面需要的参数
          }
          window.$CCDK.CCMenu.addMenu1(menuObj);
          if(flag==='detail'){
            this.$router.push({
              path: `/commonObjects/console-multi-screen/console-detail/${id}/DETAIL?dataId=${id}`,
            });
          }else if(flag===true){
            this.consoleData.data.level1Id = "menu";
          }
          return;
        }else if(!this.$store.state.navigationStyle && flag){
          const newPage = this.$router.resolve({
            path: `/commonObjects/detail/${id}/DETAIL`,
          });
          window.open(newPage.href, "_blank");
        }else{
          this.$router.push({
          path: `/commonObjects/detail/${id}/DETAIL`,
        });
        }
      } else {
        return;
      }
    },
    defaultOrder() {
      this.loading = true;
      this.getGloObj();
      this.getSingleGlobalResult();
    },
    choseOrder(index) {
      let sort = this.searchResult.fieldList[index].schemefieldName;
      //调用排序接口\
      this.loading = true;
      this.getOrderResult(sort);
    },
    //排序接口调用
    async getOrderResult(sort) {
      let obj = {
        objId: this.$route.params.searchObjId,
        page: "1",
        pageSize: "1000",
        // pageSize: "10",
        searchKeyWord: this.$route.params.userInput,
        sort: sort,
        dit: "asc",
      };
      let result = await getSingleGlobalSearchResult(obj);
      this.loading = false;
      if (result.data.count !== 0) {
        this.hasResult = true;
        this.isSingleObj = true;
        this.searchResult = result.data;
        // if (this.searchResult.count > 10) {
        //   this.resultCount = 10;
        // } else {
        //   this.resultCount = this.searchResult.count;
        // }
        this.resultCount = this.searchResult.count;
      } else {
        this.resultCount = 0;
        this.hasResult = false;
      }
    },
    choseObj(item) {
      this.$router.push(
        `/globalsearch/${item.objectInfo.objid}/${this.$route.params.userInput}`
      );
      // 在服务控制台下替换当前一级菜单数据
      if(this.$store.state.navigationStyle){
        this.consoleData.data.list.forEach((items) => {
          if (items.id == this.consoleData.data.level1Id) {
            items.params.searchObjId=item.objectInfo.objid;
          }
        });
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      }
      // this.showHot = false;

      // this.objIndex = index;
      // this.selectObjApi = this.searchResult[index].objectInfo.objectApi;
      // this.selectObjTlabel = this.searchResult[index].objectInfo.tlabel;
      // this.selectObjPre = this.searchResult[index].objectInfo.prefix;
      // this.resultCount = this.searchResult[index].dataList.length;
      // this.selectObjData = this.searchResult[index];
    },
    Single(item) {
      this.isClear = true;
      this.$refs.editableCell.forEach((item) => {
        if (item.editValue !== undefined) {
          item.editValue = undefined;
        }
      });
      this.$router.push(
        `/globalsearch/${item.objid}/${this.$route.params.userInput}`
      );
      // 在服务控制台下替换当前一级菜单数据
      if(this.$store.state.navigationStyle){
        this.consoleData.data.list.forEach((items) => {
          if (items.id == this.consoleData.data.level1Id) {
            items.params.searchObjId=item.objid;
          }
        });
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      }
    },
    openOrder() {
      this.showOrder = true;
    },
    closeOrder(e) {
      if (e.target.nodeName.toLowerCase() == "div") {
        this.showOrder = false;
        this.orderSize = e.target.innerHTML;
      }
    },
    //获取全局搜索结果
    async getGlobalResult() {
      this.isSingle = false;
      let obj = {
        page: "1",
        pageSize: "10",
        searchKeyWord: this.$route.params.userInput,
      };
      let result = await getGlobalSearchResult(obj);
      this.loading = false;
      // let arr = [];
      this.isSingleObj = false;
      this.searchResult = result.data;
      this.searchResult.filter((res, index) => {
        if (res.fieldList.length === 0) {
          this.searchResult.splice(index, 1);
        }
        return this.searchResult;
      });
      // 将数据所有的null取值转换为空
      this.searchResult.map((item) => {
        // 如果为null  赋值为0
        if (item.count == null) {
          item.count = 0;
        }else  if(item.count > 10){
          item.count = '10+'
        }
        // 如果有数据再遍历
        if (item.dataList) {
          item.dataList.map((Sitem) => {
            for (var key in Sitem) {
              if (!Sitem[key] == true) {
                Sitem[key] = "";
              }
            }
            return Sitem;
          });
        }
        return item;
      });
      if (result.data.length !== 0) {
        this.selectObjTlabel = this.searchResult[0].objectInfo.tlabel;
        this.selectObjApi = this.searchResult[0].objectInfo.objectApi;
        this.selectObjPre = this.searchResult[0].objectInfo.prefix;
        this.selectObjData = this.searchResult[0];
        this.hasResult = true;
      } else {
        this.resultCount = 0;
        this.hasResult = false;
      }
    },
    //获取单个对象搜索结果
    // 暂时改为1000条，后期增加滚动加载
    async getSingleGlobalResult(val) {
      this.isSingle = false;
      let obj = {
        objId: this.$route.params.searchObjId,
        page: "1",
        // pageSize: "10",
        pageSize: "1000",
        searchKeyWord: this.$route.params.userInput,
        // val !== undefined ? '' : this.$route.params.userInput
        conditions: val === undefined ? "" : val,
      };
      let result = await getSingleGlobalSearchResult(obj);
      this.loading = false;
      this.selectObjApi = result.data.objectInfo.objectApi;
      this.selectObjPre = result.data.objectInfo.prefix;
      this.selectObjTlabel = result.data.objectInfo.tlabel;
      if (result.data.count !== 0) {
        this.hasResult = true;
        this.isSingleObj = true;
        this.searchResult = result.data;
        // 将数据所有的null取值转换为空
        this.searchResult.dataList.map((Sitem) => {
          for (var key in Sitem) {
            if (!Sitem[key] == true) {
              Sitem[key] = "";
            }
          }
          return Sitem;
        });
        this.resultCount = this.searchResult.count;
      } else {
        this.resultCount = 0;
        this.hasResult = false;
        this.isSingleObj = false;
        this.isSingle = true;
        this.searchResult = [];
      }
      if (result.data.fieldList.length !== 0) {
        let arr = [];
        result.data.fieldList.forEach((ls) => {
          if (ls.schemefieldType !== "F") {
            arr.push(ls);
          }
        });
        this.screenDataList = arr;
        this.screenDataList.forEach((item) => {
          this.$set(item, "type", INPUTTYPE[item.schemefieldType]);
        });
      }
    },
    goto() {
      this.$router.push(`/globalsearch/all/${this.$route.params.userInput}`);
      // 在服务控制台下替换当前一级菜单数据
      if(this.$store.state.navigationStyle){
        this.consoleData.data.list.forEach((items) => {
          if (items.id == this.consoleData.data.level1Id) {
            items.params.searchObjId='all';
          }
        });
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      }
    },
    //获取全局对象
    async getGloObj() {
      let result = await getObjectSetting();
      //如果首次登录
      for (var key in result.data.selectedObjList) {
        if (result.data.selectedObjList[key].id == "") {
          result.data.selectedObjList.splice(key, 1);
        }
      }
      if (result.data.selectedObjList.length == 0) {
        this.expandMore = result.data.noSelectedObjList.filter((item) => {
          let rules =
            item.id == "account" ||
            item.id == "contact" ||
            item.id == "opportunity" ||
            item.id == "lead";
          return rules;
        });
      } else {
        this.expandMore = result.data.selectedObjList;
      }
    },
    // 清除搜索条件
    clear() {
      this.isClear = true;
      this.$refs.editableCell.forEach((item) => {
        if (item.editValue !== undefined) {
          item.editValue = undefined;
        }
      });
      this.getSingleGlobalResult();
    },
    // 搜索
    search() {
      let param = {};
      let key = "";
      let value = "";
      if (this.$refs.editableCell) {
        this.$refs.editableCell.forEach((item) => {
          if (
            item.editValue !== undefined &&
            item.editValue !== "" &&
            item.editValue !== null
          ) {
            key = item.fieldName;
            value = item.editValue;
            param[key] = value;
          }
        });
      }
      this.loading = true;
      let res = JSON.stringify(param);
      if (res === "{}") {
        this.getSingleGlobalResult();
      } else {
        this.getSingleGlobalResult(res);
      }
    },
    // 获取选项列表值
    async selectes(item) {
      if (item.schemefieldType === "L") {
        let arr = [];
        let params = {
          fieldId: item.id,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codekey,
              value: item.codekey,
            };
            arr.push(obj);
            this.selectorList = arr;
          });
        }
      }
    },
  },
  // 首次进入搜索结果页面时保存from的路径
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //  只有普通应用程序需要打开新版全局搜索，控制台应用程序维持不变
      if(!vm.$store.state.navigationStyle){
        if (to.fullPath.indexOf('globalsearch') >-1) {
        vm.$store.commit("setFromGlobalSearchPath", from.fullPath);
        }
        // 跳转到搜索结果页时，菜单栏添加搜索框
        vm.$Bus.$emit("enterSelectBtn");
      }
    });
  },
  // 离开搜索结果页的时候  通过兄弟传参  修改输入框是否清空
  beforeRouteLeave(to, from, next) {
    this.$Bus.$emit("closeSelectBtn");
    this.$bus.$emit("isClear", true);
    this.$store.commit("setFromGlobalSearchPath", "");
    next();
  },
  destroyed() {
    // 清空全局搜索的标识
    this.$store.commit("setFromGlobalSearchPath", "");
  }

};
</script>

<style lang="scss" scoped>
 .cursorBox{
    cursor: pointer;
  }
.singleHot {
  background: #ffffff;
  margin-bottom: 10px;
  border-radius: 5px;
}
.positionG {
  padding-bottom: 20px;
  background: white;
  width: 100%;
  margin-top: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
::v-deep .screen {
  width: 100%;
  padding-left: 10px;
  margin-left: -10px;
  margin-bottom: 10px;
  .screen_title {
    font-size: 14px;
    color: #080707;
    font-weight: bold;
    margin-bottom: 0px !important;

    .search {
      font-size: 14px;
      color: #006dcc !important;
      font-weight: normal;
      float: right;
      .search_item {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .el-input {
    height: 30px;
    .el-input__inner {
      height: 100%;
    }
    .el-input__icon {
      line-height: 30px !important;
    }
  }
}

::v-deep .el-collapse-item__header {
  color: #006dcc !important;
  padding-right: 10px;
}

::v-deep .el-collapse-item__content {
  .collapse {
    padding: 0 20px 0 0 !important;
  }
}
.liBox{
  display: flex;
  justify-content: space-between;
  i{
    padding-left: 20px;
    color: #2D6CFC;
  }

}
.flexBox{
  display: flex;
  justify-content: space-between;
  .el-button.is-plain{
    background: #FFFFFF;
    border-color: #2D6CFC;
    color: #2D6CFC;
  }
}
.selector {
  width: 100%;
  display: flex;
  // background: #EDF3F7;
}
.resultArea {
  width: 100%;
  display: flex;
  margin-top: 5px;
}
.resultItem {
  width: 100%;
  min-width: 100px;
  border-bottom: 1px solid #dddbda;
  padding: 8px;
  font-size: 12px;
  // 文本水平居中
  display: flex;
  align-items: center;
}
.clipText{
  overflow: hidden;
  white-space: nowrap;
  // 多余内容省略号处理-单行情况
  text-overflow: ellipsis;
}
// 自动换行,设置显示的行数为3，超出省略号展示
.autoWrap{
  // 多余内容省略号处理-多行情况
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

}
.selector-item {
  width: 100%;
  // max-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
  height: 40px;
  background: #fafaf9;
  border: 1px solid #dedcda;
  border-left: none;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // font-size: 12px;
  // font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  font-weight: 600;
  color: #3e3e3c;
}
.show-order p {
  display: inline-block;
  padding: 15px 0px 0px 17px;
  font-size: 12px !important;
}
.show-order div {
  padding: 0px 0px 0px 37px;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  cursor: pointer;
}
.divStyle:hover {
  background: #f5f5f5 !important;
  color: #006dcc !important;
}
.orderIcon {
  width: 20px;
  height: 20px;
  margin-top: 3px;
  margin-left: 5px;
}
.orderTitle {
  display: flex;
  padding: 5px 10px 10px 10px;
  font-size: 14px;
  color: #080707;
}
.order {
  display: flex;
  cursor: pointer;
}
.order p {
  margin-bottom: 0px;
  color: #006dcc;
}
.seeMore {
  position: absolute;
  top: 70px;
  right: 35px;
  color: #006dcc;
}
.resultFather {
  display: flex;
  position: relative;
  color: #333333;
  padding-left: 220px;
  margin: 10px 0px 0px 10px;
  height: 97% !important;
  overflow: hidden;
}
.firstChild {
  width: 220px;
  height: 100%;
  padding-left: 18px;
  line-height: 32px;
  margin-left: -220px;
  background-color: white;
  margin-right: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: auto;
}
.hasResult {
  position: absolute;
  left: 0px;
  top: 60px;
  width: 219px;
  background-color: white;
  padding-bottom: 50px;
}
.secondChild {
  width: 100%;
  // height: 770px;
  // background-color: white;
  border-radius: 4px;
  overflow: auto;
}
.secondChildY {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  overflow: auto;
}
.secondChild p {
  margin-bottom: 0px;
  font-size: 18px;
}
.classify {
  padding: 0px 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.classif {
  padding: 0px 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  color: #080707;
  // cursor: pointer;
}
.classifyY {
  background: #f5f5f5;
  color: #2c3e50;
}
.classify:hover {
  background: #f5f5f5 !important;
  color: #006dcc !important;
}
.noResultIcon {
  display: flex;
  justify-content: center;
}
.noResultIcon .icon {
  width: 418px;
  height: 240px;
  margin-top: 200px;
  margin-bottom: 30px;
}
.noResultInfo {
  // padding: 0px 345px 195px 346px;
  text-align: center;
  margin-bottom: 240px;
}
.resultOrder {
  display: flex;
  // padding: ;
}
</style>
